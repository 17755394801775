@font-face {
  font-family: Montserrat;
  src: url('../Resources/Montserrat-Regular.ttf');
}

*,
::before,
::after {
  box-sizing: initial;
}

.document {
  background-color: #d59241;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media screen and (max-width: 700px) {
  .hero {
    background-image: url('../Resources/Condo_Photos/pdp_Mobile.jpg');
    background-position: center center;
  }

  .last-container {
    margin-bottom: 8vw;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .section {
    padding-top: 5vw;
    margin-top: 0vw;
  }

  .first-container {
    flex-direction: column;
    justify-content: center;
    margin-bottom: 8vw;
  }

  .imageContainer__end {
    justify-content: center;
  }

  .sectionImageRight {
    margin: 0em;
    max-width: 80vw;
  }

  .imageContainer {
    display: flex;
    justify-content: center;
  }

  .sectionImageLeft {
    margin-right: 0em;
    max-width: 80vw;
    object-fit: cover;
  }

  .left-text {
    align-self: center;
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-content: center;
    min-width: 80vw;
  }

  .right-text {
    text-align: center;
    margin-bottom: 5vh;
  }

  .right-text:first {
    margin-top: 0px;
  }

  .travelbar {
    font-size: 2vw;
  }

  .greeting {
    font-size: 6vw;
  }

  .paragraph {
    font-size: 3vw;
  }

  .hero-title {
    font-size: 7vw;
  }

  .hero-sub-title {
    font-size: 5vw;
  }

  .subTitle {
    font-size: 4vw;
    margin: 2vw 3vw;
  }

  .section-two-list {
    font-size: 3.5vw;
  }

  .section-container {
    padding-right: 0;
  }

  .princesa-list {
    justify-content: center;
    font-size: 2.5vw;
  }

  .justify {
    padding-inline-start: 0px;
  }

  .section-two-list > li {
    margin-top: 1.75vh;
  }

  .houseImagesRow {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin: 0 0 0 0;
  }

  .smallImage {
    width: 67vw;
    height: 62vw;
    object-fit: cover;
    margin: 0 0 2.5vw 0;
  }

  .development-section {
    padding-top: 0px;
  }
}

@media only screen and (min-width: 701px) and (max-width: 900px) {
  .hero {
    background-image: url('../Resources/Condo_Photos/pdp_Ipad.jpg');
    background-position: center center;
  }

  .last-container {
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .first-container {
    flex-direction: column;
    justify-content: center;
  }

  .section {
    margin-top: 5vh;
  }

  .imageContainer__end {
    justify-content: center;
  }

  .sectionImageRight {
    margin: 0em;
    max-width: 75%;
  }

  .imageContainer {
    display: flex;
    justify-content: center;
  }

  .sectionImageLeft {
    margin-right: 0em;
    object-fit: cover;
    max-width: 75%;
  }

  .left-text {
    align-self: center;
    width: 80vw;
  }

  .right-text {
    text-align: center;
    margin-top: 3vh;
    margin-bottom: 5vh;
  }

  .travelbar {
    font-size: 2vw;
  }

  .greeting {
    font-size: 6vw;
  }

  .paragraph {
    font-size: 3vw;
  }

  .hero-title {
    font-size: 5.5vw;
  }

  .hero-sub-title {
    font-size: 3.5vw;
  }

  .subTitle {
    font-size: 3.25vw;
  }

  .section-two-list {
    font-size: 2.5vw;
  }

  .princesa-list {
    justify-content: center;
    font-size: 2vw;
  }

  .section-two-list > li {
    margin-top: 1.75vh;
  }

  .houseImagesRow {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin: 0 0 0 0;
  }

  .smallImage {
    width: 40vw;
    height: 36vw;
    object-fit: cover;
    margin: 0 0 2.5vw 0;
  }
}

@media screen and (min-width: 901px) {
  .hero {
    background-image: url('../Resources/Condo_Photos/pdp_1600x750.jpg');
    background-position: center center;
  }

  .section {
    margin-top: 5vh;
  }

  .justify {
    padding-inline-start: 20px;
  }

  .imageContainer__end {
    justify-content: flex-end;
  }

  .sectionImageRight {
    margin-left: 5em;
    max-width: 75%;
  }

  .sectionImageLeft {
    margin-right: 5em;
    max-width: 75%;
  }

  .first-container {
    justify-content: space-between;
  }

  .last-container {
    justify-content: space-between;
  }

  .left-text {
    text-align: justify;
  }

  .first-list {
    width: 15rem;
  }

  .right-text {
    text-align: justify;
  }

  .travelbar {
    font-size: 2vw;
  }

  .greeting {
    font-size: 6vw;
  }

  .paragraph {
    font-size: 3vw;
  }

  .hero-title {
    font-size: 3.5vw;
  }

  .hero-sub-title {
    font-size: 3vw;
  }

  .subTitle {
    font-size: 2.5vw;
  }

  .section-two-list {
    font-size: 1.5vw;
  }

  .princesa-list {
    font-size: 1.15vw;
  }

  .princesa-list > ul > li {
    margin-bottom: 1.25vh;
  }

  .list-align {
    font-size: 1.35vw;
  }

  .list-align > li {
    margin-top: 2.75vh;
  }

  .last-container {
    margin: 5vw 0 5vw 6vw;
  }

  .section-container {
    padding-right: 0px;
  }

  /* House Classes */

  .houseImagesRow {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    margin: 5vh 0 5vh 0;
  }

  .smallImage {
    width: 40vw;
    height: 37vw;
    object-fit: cover;
  }
}

.nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: rgb(97, 173, 206);
}

.nav-links {
  width: 20%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
}

.img {
  border-style: none;
}

.App-link {
  color: #61dafb;
}

.containerBar {
  position: relative;
  top: 0;
  z-index: 1;
  background-color: rgb(97, 173, 206);
  margin-bottom: 0%;
}

.menuList {
  width: 100%;
  margin-top: 1rem;
}

.travelbar {
  size: 5rem;
  color: white;
  display: block;
  width: 35%;
  margin-top: 0;
  line-height: 3rem;
  text-align: center;
  float: left;
}

.travelbar:hover {
  background-color: whitesmoke;
  cursor: pointer;
  color: #013ee5;
}

.text {
  font-family: Montserrat;
}

.logo {
  max-width: 100px;
  max-height: 50px;
  min-width: 1em;
  min-height: 1em;
  left: 10;
  cursor: pointer;
}

.mainText {
  font-weight: bold;
}

.mainPicture {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}

.greeting {
  color: #013ee5;
  text-align: center;
  font-weight: 800;
}

.paragraph {
  text-align: justify;
}

.center {
  text-align: center !important;
}

.homeText {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

.slide-container {
  display: block;
  width: 75%;
  align-self: center;
  margin-top: 0px;
  margin-left: auto;
  margin-right: auto;
}

/*Hero Section*/

.hero {
  position: relative;
  opacity: 0.9;
  width: 100%;
  min-height: 95vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero::before {
  background-size: cover;
  width: 100%;
  height: 100%;
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
}

.hero::after {
  background-color: #d59241 !important;
  width: 100%;
  height: 100%;
  content: '';
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
  opacity: 0.2;
}

.flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.hero-message {
  color: #fff;
  text-shadow: #343a40 3px 3px;
  min-width: 100%;
  min-height: 20em;
  position: relative;
}

.hero-message::before {
  content: '';
  display: block;
  position: absolute;
  margin-left: 0;
  min-width: 100%;
  min-height: 20em;
  z-index: -1;
}

.hero-title {
  width: 100%;
  display: block;
  text-align: center;
  margin-right: 0 !important;
  margin-left: 0 !important;
  margin: 3% 0;
  text-transform: uppercase;
  font-weight: bolder;
}

.hero-sub-title {
  width: 100%;
  display: block;
  text-align: center;
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.section {
  width: 100%;
  display: flex;
  color: black;
}

.section:nth-child(even) {
  background-color: #fff;
}

.section:nth-child(odd) {
  color: #fff;
}

.development-section {
  display: inline-table;
  width: 100%;
  background-color: #fff;
}

.section-two-list {
  max-width: 70vw;
  text-align: justify;
  margin: 0;
  padding-left: 10px;
}

.section-container {
  width: 90%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0px;
}

.left-text {
  align-content: center;
  padding-left: initial;
  min-width: 50%;
  /* padding-left: 10rem; */
}

.princesa-list {
  text-align: left !important;
  display: flex;
}

.second-list {
  align-content: flex-end;
}

.condoLogos {
  max-height: 15vmin;
}

.devLogoContainer {
  width: 100%;
}

.imageContainer {
  align-self: stretch;
}

.imageContainer__end {
  width: 100%;
  display: flex;
  align-self: stretch;
  position: relative;
}

.sectionImageRight {
  display: flex;
  align-self: flex-start;
  padding-top: 2rem;
}

.right-text {
  justify-content: flex-start;

  float: right;
  padding-right: initial;
  min-width: 50%;
}

.sectionImageLeft {
  display: flex;
  align-items: flex-start;
}

.justify {
  text-align: justify;
}

.list-align {
  text-align: left !important;
}
