@media screen and (max-width: 700px) {
  .footer {
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    font-size: 1.5vh;
    padding: 1.5vw 0 3vw 0;
  }

  .footer-section div {
    margin: 1.25vw 0 0 0;
  }

  .facebook-logo {
    height: 4.5vw;
    margin-left: 1vw;
    position: relative;
    top: 0vh;
  }
}

@media only screen and (min-width: 701px) and (max-width: 900px) {
  .footer {
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    font-size: 2vw;
    padding: 1.25vw 0 1.25vw 0;
  }

  .footer-section div {
    margin: 1vw 0 0 0;
  }

  .facebook-logo {
    height: 3vw;
    margin-left: 1vw;
    position: relative;
    top: 0vh;
  }
}

@media screen and (min-width: 901px) {
  .footer {
    font-size: 1.2vw;
    text-align: left;
  }

  .facebook-logo {
    height: 1.75vw;
    margin-left: 0.5vw;
    position: relative;
    top: 0.1vw;
  }

  .footer-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.25rem;
  }

  .footer-phone {
    width: 65%;
    margin-left: 1vw;
  }

  .footer-company {
    display: block;
    text-align: center;
  }

  .footer-social {
    display: flex;
    margin-right: 1vw;
  }
}

.footer {
  background-color: rgb(97, 173, 206);
  color: #fff;
}

.facebook-logo {
  cursor: pointer;
  align-self: center;
}
