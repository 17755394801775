

@font-face { font-family: Montserrat; src: url('../../Resources/Montserrat-Regular.ttf'); } 

.Main {
    background-color: #fff;
    position: relative;
    top: -1.5rem !important;
    
}

.text {
    font-family: Montserrat;
}

.greeting {
    color: #013ee5;
    font-size: 34px;
    text-align: center;
    font-weight: 800;
    margin-top: 3rem !important;
    margin-bottom: .5rem !important;
}

.paragraph {
    font-size: 20px;
    text-align: justify;
}

.center {
    text-align: center !important;
}

.gallery {
    padding: 40px;
    padding-bottom: 35rem;
}

.gallery-photo {
  width: 18rem;
  height: 18rem;
  margin: 2rem;
  margin-Top: 1rem;
}